@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000;
}

@keyframes float {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0.1rem, -0.1rem);
  }
  50% {
    transform: translate(-0.1rem, -0.1rem);
  }
  75% {
    transform: translate(0.2rem, -0.2rem);
  }
}

.animate-float {
  animation: float 4s infinite ease-in-out alternate;
}

.team-item .card {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.team-item .card .front-image,
.team-item .card .back-image {
  position: absolute;
  top: 0;
  left: 0;

  backface-visibility: hidden;
  transition: opacity 0.5s, transform 0.5s;
}

.team-item .card .back-image {
  transform: rotateY(180deg);
}

.team-item .no-pointer-events {
  pointer-events: none;
}

.team-item:hover .card {
  transform: rotateY(180deg);
}

.team-item:hover .card .front-image {
  opacity: 0;
}

.team-item:hover .card .back-image {
  opacity: 1;
}

.scroll-hidden::-webkit-scrollbar {
  width: 0;
}

.scroll-hidden::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 999px;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.2s ease-in-out;
}

.global-shadow-fire {
  filter: drop-shadow(0.1rem 0.2rem 0.7rem rgba(170, 70, 0, 1));
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}